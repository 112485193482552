<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { viewContactsOptions, roleOptions } from '@/Constants'

const emit = defineEmits([ 'closeModal' ])

const useAuth = useAuthStore()

const companiesOptions = computed(() =>
  useAuth.companies
    .filter((el: any) => el.id !== useAuth.company.id)
    .map((obj: any) => ({ value: obj.id, label: obj.name }))
)

interface ChooseUsers {
  label: string
  value: string
  role?: string | null
  contact_view?: string | null
}

const $q = useQuasar()
const step = ref(1)
const chooseCompany = ref()
const chooseUsers = ref<ChooseUsers[]>([])
const loadedUsers = ref<ChooseUsers[]>([])
const loadedUsersOptions = ref<ChooseUsers[]>([])
const loadedCompany = ref<{ id: number; name: string } | undefined>(undefined)
const formErros = ref({})

const loadUsersByCompany = async () => {
  return await new Promise<true>((resolve, reject) => {
    if (!chooseCompany.value) {
      $q.notify({
        color: 'red',
        icon: 'close',
        message: 'Selecione uma empresa',
      })
      reject(new Error('Selecione uma empresa'))
    }

    if (
      loadedCompany.value &&
      chooseCompany.value !== loadedCompany.value?.id
    ) {
      loadedCompany.value = undefined
      loadedUsers.value = []
      chooseUsers.value = []
    }

    window.axios
      .get(
        route('dashboard.loadUsersByCompany', {
          _query: { company: chooseCompany.value },
        })
      )
      .then((response) => {
        const data = response.data
        loadedCompany.value = {
          id: data.id,
          name: data.name,
        }
        loadedUsers.value = response.data.users.map((el: any) => ({
          label: el.name,
          value: `id:${el.user_id}!email:${el.email}`,
        }))
        resolve(true)
      })
      .catch((e) => reject(e))
  })
}

const selectUsers = () => {
  chooseUsers.value = chooseUsers.value.map((el) => ({
    ...el,
    contact_view: null,
    role: null,
  }))
}

const importUsers = () => {
  router.post(
    route('dashboard.user.import'),
    {
      users: chooseUsers.value,
    },
    {
      onSuccess: () => {
        $q.notify({
          color: 'positive',
          icon: 'done',
          message: 'Importação finalizada com sucesso!',
        })
        emit('closeModal')
      },
      onError: (response) => {
        formErros.value = response
      },
    }
  )
}

function filterUsers(val: any, update: any) {
  if (val === '') {
    update(() => {
      loadedUsersOptions.value = loadedUsers.value
    })
    return
  }

  update(() => {
    const needle = val.toLowerCase()
    loadedUsersOptions.value = loadedUsers.value.filter(
      (v) => v.label.toLowerCase().indexOf(needle) > -1
    )
  })
}
</script>
<template>
  <q-stepper
    ref="stepper"
    v-model="step"
    header-nav
    color="primary"
    flat
    animated
  >
    <q-step
      :name="1"
      title="Selecione a empresa"
      icon="o_apartment"
      :done="step > 1"
      :header-nav="step > 1"
    >
      <q-select
        v-model="chooseCompany"
        filled
        map-options
        emit-value
        :options="companiesOptions"
        label="Empresa"
      />

      <q-stepper-navigation>
        <q-btn
          :disable="!chooseCompany"
          color="primary"
          label="Avançar"
          @click="
            () => {
              loadUsersByCompany().then(() => {
                done1 = true
                step = 2
              })
            }
          "
        />
      </q-stepper-navigation>
    </q-step>

    <q-step
      :name="2"
      title="Selecione os usuários"
      icon="o_person"
      :done="step > 2"
      :header-nav="step > 2"
    >
      <q-select
        v-model="chooseUsers"
        filled
        multiple
        use-input
        map-options
        label="Usuários"
        input-debounce="0"
        :options="loadedUsersOptions"
        @filter="filterUsers"
      />
      <q-stepper-navigation>
        <q-btn
          :disable="!chooseUsers.length"
          color="primary"
          label="Avançar"
          @click="
            () => {
              selectUsers()
              done2 = true
              step = 3
            }
          "
        />
        <q-btn
          flat
          color="red"
          label="Voltar"
          class="q-ml-sm"
          @click="step = 1"
        />
      </q-stepper-navigation>
    </q-step>

    <q-step
      :name="3"
      title="Nível de acesso"
      icon="o_add_comment"
      :header-nav="step > 3"
    >
      <q-form @submit.prevent="importUsers">
        <q-markup-table flat bordered>
          <thead>
            <tr>
              <th class="text-left tw-w-[10%]">Nome</th>
              <th class="text-left tw-w-[10%]">E-mail</th>
              <th class="text-center tw-w-[40%]">Nível de Acesso</th>
              <th class="text-center tw-w-[40%]">Visualização de Contatos</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(users, index) in chooseUsers" :key="users.value">
              <td class="text-left">
                {{ users.label }}
              </td>
              <td class="text-left">
                {{ users.value.split('!')[1].split('email:')[1] }}
              </td>
              <td class="text-center">
                <q-select
                  v-model="chooseUsers[index].role"
                  filled
                  map-options
                  emit-value
                  required
                  label="Nível de Acesso"
                  :options="roleOptions"
                  :error="
                    Object.keys(formErros).includes(`users.${index}.role`)
                      ? true
                      : false
                  "
                />
              </td>
              <td class="text-center">
                <q-select
                  v-model="chooseUsers[index].contact_view"
                  filled
                  map-options
                  emit-value
                  required
                  label="Visualização de Contatos"
                  :options="viewContactsOptions"
                  :error="
                    Object.keys(formErros).includes(
                      `users.${index}.contact_view`
                    )
                      ? true
                      : false
                  "
                />
              </td>
            </tr>
          </tbody>
        </q-markup-table>

        <q-stepper-navigation>
          <q-btn type="submit" color="primary" label="Importar" />
          <q-btn
            flat
            type="button"
            color="red"
            label="Voltar"
            class="q-ml-sm"
            @click="step = 2"
          />
        </q-stepper-navigation>
      </q-form>
    </q-step>
  </q-stepper>
</template>
