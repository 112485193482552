<script setup lang="ts">
import { StyleValue } from 'vue'

const asset: any = inject('asset')

const agencyStore = useAgencyStore()
const logo = computed(() => agencyStore.logo)

const boxLeftStyles = computed(() => {
  const styles: StyleValue = {}
  if (
    !agencyStore.customizations?.pages?.auth?.boxLeft?.bgImage &&
    agencyStore.customizations?.pages?.auth?.boxLeft?.bgColor
  ) {
    styles['background-color'] =
      agencyStore.customizations?.pages?.auth?.boxLeft?.bgColor
  } else if (agencyStore.customizations?.pages?.auth?.boxLeft?.bgImage) {
    styles['background-image'] =
      `url('${agencyStore.customizations?.pages?.auth?.boxLeft?.bgImage}')`
  } else {
    styles['background-image'] =
      `url('${asset('images/ipchat-auth-box-left.jpeg')}')`
  }

  if (agencyStore.customizations?.pages?.auth?.boxLeft?.textColor) {
    styles.color = agencyStore.customizations?.pages?.auth?.boxLeft?.textColor
  }

  return styles
})
</script>

<template>
  <section class="section-all">
    <article class="box-left" :style="boxLeftStyles">
      <section
        v-if="agencyStore.customizations?.pages?.auth?.boxLeft?.showLogo"
        class="overlay"
      >
        <img
          :src="logo ? logo : $asset('images/logo.png')"
          class="tw-max-w-[50%]"
          :title="agencyStore.platformTitle"
          :aria-label="agencyStore.platformTitle"
        />
      </section>
    </article>

    <article class="box-right">
      <section
        class="tw-inline-flex tw-flex-wrap tw-w-[90%] tw-justify-center tw-max-w-[420px]"
      >
        <slot />
      </section>
    </article>
  </section>
</template>
<style scoped>
.overlay {
  @apply tw-absolute tw-bg-black/30 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center;
}

.box-left {
  @apply tw-relative tw-shadow-xl tw-flex tw-h-screen tw-p-4 tw-items-center tw-justify-center tw-bg-contain tw-bg-no-repeat tw-aspect-square;
}

.box-right {
  @apply tw-w-full tw-flex tw-items-center tw-p-6 tw-h-screen tw-justify-center;
}

.section-all {
  @apply tw-w-full;
  @apply tw-h-screen;
  @apply tw-flex;
  @apply tw-flex-wrap;
  @apply sm:tw-flex-nowrap;
}
</style>
