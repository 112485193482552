/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios'
import Pusher from 'pusher-js'

import axiosRetry from 'axios-retry'
import Vapor from 'laravel-vapor'
import { DateTime } from 'luxon'
import { Notify } from 'quasar'
import Queue from 'queue'

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

window.DateTime = DateTime
window.queue = new Queue({ results: [], autostart: true, concurrency: 2 })
window.instanceStatusQueue = new Queue({ results: [], autostart: true, concurrency: 3, timeout: 20_000 })
window.Vapor = Vapor
window.axios = axios.create()
axiosRetry(window.axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
})

window.axios.interceptors.response.use(undefined, function (error) {
  if (
    error.response?.status === 419 ||
    error.response?.status === 401 ||
    error.response?.status === 403
  ) {
    window.location.reload()
    return error
  }

  if (error.response?.status === 429) {
    Notify.create({
      type: 'error',
      message: '[RATELIMIT] A quantidade de solicitações foi atingida, tente novamente em 1 minuto.'
    })
  }

  return Promise.reject(error)
})

// window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['X-Skip-Inertia'] = true
window.Pusher = Pusher
Pusher.logToConsole = import.meta.env.VITE_APP_ENV !== 'production'
