<script setup lang="ts">
import { useWakeLock } from '@vueuse/core'
import { usePage } from '@inertiajs/vue3'
import Echo from 'laravel-echo'

const authProps = computed(() => usePage().props.auth)

const useAuth = useAuthStore()
const agencyStore = useAgencyStore()

onBeforeMount(() => {
  window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: [ 'ws', 'wss' ],
  })
})

onMounted(async () => {
  useAuth.init()

  const { agency, user, company } = authProps.value

  agencyStore.setAgency({ agency })

  useAuth.makeSession({
    user,
    company,
  })

  const { isSupported, request } = useWakeLock()

  if (isSupported) {
    request('screen')
  }

})
</script>
<template>
  <slot />
</template>
