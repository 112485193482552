import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  legacy: false,
  locale: 'pt-br',
  fallbackLocale: 'pt-br',
  messages: {
    'pt-br': {
      finished: 'finalizado | finalizados',
      active: 'ativo | ativos',
      contact: 'contato',
      attendant: 'atendente',
      admin: 'administrador',
      minute: '{n} minuto | {n} minutos',
      day: '{n} dia | {n} dias',
      connected: 'conectado',
      disconnected: 'desconectado',
      authenticated: 'autenticado',
      init: 'iniciando',
      contact_view: {
        all: 'todos',
        himself: 'os próprios contatos'
      },
      tableCountResults: {
        showing: 'Exibindo',
        results: '{total} resultado | {total} resultados',
        linked: '@:tableCountResults.showing {count} de @:tableCountResults.results'
      }
    },
  }
})
