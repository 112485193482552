export function extractTwoFirstName(fullname: string) {
  // Divide o nome completo em partes usando o espaço como separador
  const slicedName = fullname.split(' ')

  // Verifica se há pelo menos dois nomes
  if (slicedName.length >= 2) {
    // Retorna os dois primeiros nomes
    return slicedName.slice(0, 2).join(' ')
  } else {
    // Retorna o nome completo se não houver dois nomes
    return fullname
  }
}

// Função para extrair as iniciais do primeiro nome e sobrenome
export function extractInitials(fullname: string) {
  // Divide o nome completo em partes usando o espaço como separador
  const slicedName = fullname.split(' ')

  // Verifica se há pelo menos um nome
  if (slicedName.length >= 1) {
    const primeiraInicial = slicedName[0].charAt(0).toUpperCase()

    if (slicedName.length >= 2) {
      const segundaInicial = slicedName[1].charAt(0).toUpperCase()
      return primeiraInicial + segundaInicial
    }

    return primeiraInicial
  } else {
    return 'N/A' // Retorna "N/A" se não houver nome
  }
}

export const nl2br = (str: string, isXhtml?: boolean) => {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>'
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  )
}
