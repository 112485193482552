<script setup lang="ts">
import { router } from '@inertiajs/vue3'
// import InternalChatSticky from '@/Components/InternalChatSticky.vue'
import _ from 'lodash'

const $q = useQuasar()

const style = computed(() => ({
  height: $q.screen.height + 'px',
}))

const dashboardStore = useDashboardStore()
const agencyStore = useAgencyStore()
const modalStore = useModalsStore()
const authStore = useAuthStore()
const useBot = useBotSetting()

const agency = computed(() => agencyStore.agency)

const hasCustomQItem = computed(
  () => !_.isEmpty(agencyStore.customizations?.pages?.admin?.menu?.activeLink),
)
const customQItemBgColor = computed(
  () => agencyStore.customizations?.pages?.admin?.menu?.activeLink?.bgColor,
)
const customQItemTextColor = computed(
  () => agencyStore.customizations?.pages?.admin?.menu?.activeLink?.textColor,
)

const companiesOptions = computed(() =>
  authStore.companies
    .map((obj: any) => ({ value: obj.id, label: obj.name }))
    .filter((el: any) => el.value !== authStore.company.id),
)

const leftDrawerOpen = ref(false)

const toggleLeftDrawer = () => (leftDrawerOpen.value = !leftDrawerOpen.value)
const drawerClick = (e: Event) => {
  if (dashboardStore.menu.miniState) {
    dashboardStore.menu.miniState = false
    e.stopPropagation()
  }
}

const goTo = (_route: string) => {
  router.visit(_route, { replace: true })
}

const changeCompany = (company: number) => {
  router.visit(route('dashboard.changeCompany', { _query: { company } }), {
    method: 'post',
    replace: true,
    onBefore: () => useBot.$reset(),
    onSuccess: () => {
      window.location.reload()
    },
  })
}
</script>

<template>
  <q-layout view="hHh LpR lFf" :style="style" container>
    <!-- <InternalChatSticky /> -->
    <q-header
      reveal
      bordered
      :class="{
        '!tw-bg-wc-gray':
          !agencyStore.customizations?.pages?.admin?.header?.bgColor,
        'text-white':
          !agencyStore.customizations?.pages?.admin?.header?.textColor,
      }"
      :style="{
        backgroundColor: `${agencyStore.customizations?.pages?.admin?.header?.bgColor} !important`,
        color: `${agencyStore.customizations?.pages?.admin?.header?.textColor} !important`,
      }"
    >
      <q-toolbar>
        <q-btn dense flat round icon="o_menu" @click="toggleLeftDrawer" />

        <q-toolbar-title>
          <template v-if="_.isEmpty(agency)"> IPChat </template>
          <template v-else>
            <template v-if="_.isEmpty(agency.logo)">
              {{ agency.platform_title ?? "Multiatendimento" }}
            </template>
            <img
              v-else
              :src="agency.logo"
              class="tw-max-w-[200px] tw-max-h-8"
            />
          </template>
        </q-toolbar-title>

        <q-btn-dropdown
          stretch
          flat
          :label="authStore.company.name"
          :disable="!authStore.hasManyCompanies"
        >
          <q-list>
            <q-item-label header> Empresas </q-item-label>
            <q-item
              v-for="item in companiesOptions"
              :key="`company.${item.value}`"
              v-close-popup
              clickable
              tabindex="0"
              @click="changeCompany(item.value)"
            >
              <q-item-section avatar>
                <q-avatar
                  icon="o_apartment"
                  color="primary"
                  text-color="white"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ item.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-separator dark vertical />
        <q-btn flat stretch dense icon="o_person" label="Perfil">
          <q-menu>
            <div class="row no-wrap q-pa-md">
              <div class="column">
                <div class="text-h6 q-mb-md">Meu Perfil</div>
                <q-toggle
                  v-model="authStore.config.notification.alert"
                  label="Alerta de Notificação"
                />
                <q-toggle
                  v-model="authStore.config.notification.sound"
                  label="Som de Notificação"
                />

                <div class="tw-flex tw-mt-1 tw-justify-center">
                  <q-btn
                    v-close-popup
                    color="primary"
                    label="Editar Perfil"
                    push
                    size="sm"
                    @click="
                      () =>
                        router.visit(route('dashboard.profile.show'), {
                          replace: true,
                        })
                    "
                  />
                </div>
              </div>

              <q-separator vertical inset class="q-mx-lg" />

              <div class="column items-center">
                <q-avatar size="72px" color="primary" text-color="white">
                  <template v-if="authStore.avatar">
                    <q-img :src="authStore.avatar" :ratio="1 / 1" />
                  </template>
                  <template v-else>
                    {{ extractInitials(authStore.user.name) }}
                  </template>
                </q-avatar>

                <div
                  class="tw-text-center tw-text-[0.85rem] tw-mt-4 tw-mb-1 tw-font-medium"
                >
                  {{ extractTwoFirstName(authStore.user.name) }}
                </div>

                <q-btn
                  v-close-popup
                  color="primary"
                  label="Sair"
                  push
                  size="sm"
                  @click="
                    () =>
                      router.visit('/logout', {
                        method: 'post',
                        replace: true,
                        preserveState: false,
                      })
                  "
                />
              </div>
            </div>
          </q-menu>
        </q-btn>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      side="left"
      visible
      bordered
      :mini="!leftDrawerOpen || dashboardStore.menu.miniState"
      @click.capture="drawerClick"
    >
      <MenuDecorationOrnament v-if="_.isEmpty(agency)" />
      <q-scroll-area
        style="height: calc(100% - 0.3rem)"
        :thumb-style="{ width: '0.363rem', borderRadius: '0px' }"
        :horizontal-thumb-style="{ opacity: '0' }"
      >
        <q-list padding>
          <q-item
            v-ripple
            clickable
            :active-class="hasCustomQItem ? 'customQItem' : undefined"
            :active="route().current('dashboard.index')"
            @click="goTo(route('dashboard.index'))"
          >
            <q-item-section avatar>
              <q-icon name="o_home" />
            </q-item-section>

            <q-item-section> Início </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :active="route().current('chat.index')"
            @click="goTo(route('chat.index'))"
          >
            <q-item-section avatar>
              <q-icon name="o_chat" />
            </q-item-section>

            <q-item-section> Chat </q-item-section>
          </q-item>

          <q-item
            v-ripple
            clickable
            :active-class="hasCustomQItem ? 'customQItem' : undefined"
            :active="route().current('dashboard.files.*')"
            @click="goTo(route('dashboard.files.index'))"
          >
            <q-item-section avatar>
              <q-icon name="o_folder_open" />
            </q-item-section>

            <q-item-section> Arquivos </q-item-section>
          </q-item>

          <q-expansion-item
            expand-separator
            icon="o_bar_chart"
            label="Relatórios"
            :default-opened="
              route().current('dashboard.reports.*') ||
              route().current('dashboard.v2.reports.*')
            "
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.reports')"
              @click="goTo(route('dashboard.reports'))"
            >
              <q-item-section avatar>
                <q-icon name="o_receipt_long" />
              </q-item-section>

              <q-item-section> Visão Geral </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.v2.reports.summary')"
              @click="goTo(route('dashboard.v2.reports.summary'))"
            >
              <q-item-section avatar>
                <q-icon name="o_summarize" />
              </q-item-section>

              <q-item-section> Sumário </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.v2.reports.exports')"
              @click="goTo(route('dashboard.v2.reports.exports'))"
            >
              <q-item-section avatar>
                <q-icon name="o_system_update_alt" />
              </q-item-section>

              <q-item-section> Exportações </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            icon="o_tag"
            label="Etiquetas"
            :default-opened="route().current('dashboard.tag.*')"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.tag.create')"
              @click="goTo(route('dashboard.tag.create'))"
            >
              <q-item-section avatar>
                <q-icon name="o_new_label" />
              </q-item-section>

              <q-item-section> Cadastrar Etiqueta </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.tag.index')"
              @click="goTo(route('dashboard.tag.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_bookmarks" />
              </q-item-section>

              <q-item-section> Listar Etiquetas </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            icon="o_manage_accounts"
            label="Usuários"
            :default-opened="
              route().current('dashboard.user.*') ||
              route().current('dashboard.*.user.*')
            "
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="
                route().current('dashboard.user.create') || modalStore.openCreateNewUserModal
              "
              @click="dashboardStore.createNewUser()"
            >
              <q-item-section avatar>
                <q-icon name="o_person_add" />
              </q-item-section>

              <q-item-section> Cadastrar Usuário </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.user.index')"
              @click="goTo(route('dashboard.user.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_person_search" />
              </q-item-section>

              <q-item-section> Listar Usuários </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            icon="o_groups"
            label="Departamentos"
            :default-opened="route().current('dashboard.department.*')"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.department.create')"
              @click="goTo(route('dashboard.department.create'))"
            >
              <q-item-section avatar>
                <q-icon name="o_group_add" />
              </q-item-section>

              <q-item-section> Cadastrar Departamento </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.department.index')"
              @click="goTo(route('dashboard.department.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_groups" />
              </q-item-section>

              <q-item-section> Listar Departamento </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            icon="o_hub"
            label="Integrações/WhatsApp"
            :default-opened="route().current('dashboard.inbox.*')"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.inbox.index')"
              @click="goTo(route('dashboard.inbox.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_dns" />
              </q-item-section>

              <q-item-section> Listar Instâncias </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            :default-opened="
              route().current('dashboard.*contact.*') ||
              route().current('dashboard.group.*')
            "
            icon="o_perm_contact_calendar"
            label="Contatos"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.contact.create')"
              @click="goTo(route('dashboard.contact.create'))"
            >
              <q-item-section avatar>
                <q-icon name="o_contact_page" />
              </q-item-section>

              <q-item-section> Cadastrar Contato </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.import-contact.*')"
              @click="goTo(route('dashboard.import-contact.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_upload_file" />
              </q-item-section>

              <q-item-section> Importar Contato </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.contact.index')"
              @click="goTo(route('dashboard.contact.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_contacts" />
              </q-item-section>

              <q-item-section> Listar Contatos </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.group.index')"
              @click="goTo(route('dashboard.group.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_groups" />
              </q-item-section>

              <q-item-section> Listar Grupos </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            :default-opened="route().current('dashboard.v2.conversation*')"
            icon="o_chat"
            label="Atendimentos"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.v2.conversation.index')"
              @click="goTo(route('dashboard.v2.conversation.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_forum" />
              </q-item-section>

              <q-item-section> Listar Atendimentos </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="
                route().current('dashboard.v2.conversation.searchProtocolIndex')
              "
              @click="
                goTo(route('dashboard.v2.conversation.searchProtocolIndex'))
              "
            >
              <q-item-section avatar>
                <q-icon name="o_speaker_notes" />
              </q-item-section>

              <q-item-section> Pesquisar Protocolo </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            :default-opened="route().current('dashboard.v2.api.*')"
            icon="o_api"
            label="API"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.v2.api.manageTokens.index')"
              @click="goTo(route('dashboard.v2.api.manageTokens.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_lock" />
              </q-item-section>

              <q-item-section> Gerenciar Tokens </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              href="https://documenter.getpostman.com/view/26902344/2sA2xpUAMc"
              target="_blank"
            >
              <q-item-section avatar>
                <q-icon name="o_description" />
              </q-item-section>

              <q-item-section>
                <div>
                  Documentação
                  <q-icon name="o_link" />
                </div>
              </q-item-section>
            </q-item>
          </q-expansion-item>

          <q-expansion-item
            expand-separator
            icon="o_settings"
            label="Configurações"
            :default-opened="route().current('dashboard.settings.*')"
          >
            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.settings.index')"
              @click="goTo(route('dashboard.settings.index'))"
            >
              <q-item-section avatar>
                <q-icon name="o_tune" />
              </q-item-section>

              <q-item-section> Configurações Gerais </q-item-section>
            </q-item>

            <q-item
              v-if="authStore.hasCompanyServiceTime"
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="route().current('dashboard.settings.bussinessDayIndex')"
              @click="goTo(route('dashboard.settings.bussinessDayIndex'))"
            >
              <q-item-section avatar>
                <q-icon name="o_timer" />
              </q-item-section>

              <q-item-section> Horários de Atendimento </q-item-section>
            </q-item>

            <q-item
              v-ripple
              clickable
              :active-class="hasCustomQItem ? 'customQItem' : undefined"
              :active="
                route().current('dashboard.settings.satisfactionSurvey.index')
              "
              @click="
                goTo(route('dashboard.settings.satisfactionSurvey.index'))
              "
            >
              <q-item-section avatar>
                <q-icon name="o_mood" />
              </q-item-section>

              <q-item-section> Pesquisa de Satisfação </q-item-section>
            </q-item>

            <q-expansion-item
              expand-separator
              icon="o_headset_mic"
              label="Chat"
            >
              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.shortcut.index')"
                @click="goTo(route('dashboard.shortcut.index'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_quickreply" />
                </q-item-section>

                <q-item-section> Mensagens Rápidas </q-item-section>
              </q-item>

              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.reason.index')"
                @click="goTo(route('dashboard.reason.index'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_feedback" />
                </q-item-section>

                <q-item-section> Motivos de Finalização </q-item-section>
              </q-item>
            </q-expansion-item>

            <q-expansion-item
              expand-separator
              icon="o_smart_toy"
              label="Chatbot"
            >
              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.settings.greetingsIndex')"
                @click="goTo(route('dashboard.settings.greetingsIndex'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_forum" />
                </q-item-section>

                <q-item-section> Saudações </q-item-section>
              </q-item>

              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.settings.actionModeIndex')"
                @click="goTo(route('dashboard.settings.actionModeIndex'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_precision_manufacturing" />
                </q-item-section>

                <q-item-section> Modo de Ação </q-item-section>
              </q-item>

              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.chatbot.message.*')"
                @click="goTo(route('dashboard.chatbot.message.index'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_chat" />
                </q-item-section>

                <q-item-section> Mensagens </q-item-section>
              </q-item>

              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.chatbot.form.*')"
                @click="goTo(route('dashboard.chatbot.form.index'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_checklist" />
                </q-item-section>

                <q-item-section> Formulários </q-item-section>
              </q-item>

              <q-item
                v-ripple
                clickable
                :active-class="hasCustomQItem ? 'customQItem' : undefined"
                :active="route().current('dashboard.chatbot.index')"
                @click="goTo(route('dashboard.chatbot.index'))"
              >
                <q-item-section avatar>
                  <q-icon name="o_settings" />
                </q-item-section>

                <q-item-section> Configurar Bot </q-item-section>
              </q-item>
            </q-expansion-item>
          </q-expansion-item>
        </q-list>
      </q-scroll-area>

      <div class="q-mini-drawer-hide absolute" style="top: 18px; right: -13px">
        <q-btn
          dense
          round
          color="primary"
          icon="chevron_left"
          size="sm"
          @click="dashboardStore.menu.miniState = true"
        />
      </div>
    </q-drawer>

    <q-page-container>
      <div class="tw-px-4 tw-py-2">
        <slot />
      </div>
    </q-page-container>

    <q-dialog v-model="modalStore.openCreateNewUserModal" persistent>
      <q-card class="text-white" style="width: 800px; max-width: 80vw">
        <q-card-section class="bg-primary row items-center">
          <div class="text-h6">Importar usuário</div>
          <q-space />
          <q-btn v-close-popup icon="o_close" flat round dense />
        </q-card-section>

        <q-card-section class="tw-bg-white tw-text-wc-gray">
          <ImportUserStepper
            @close-modal="() => modalStore.toggleCreateNewUserModal()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-layout>
</template>
<style scoped>
.customQItem {
  background-color: v-bind(customQItemBgColor);
  color: v-bind(customQItemTextColor);
}
</style>
